import { navigate } from 'gatsby'
import React, { Suspense } from 'react'
import { compose } from 'recompose'
import { Loading } from '../../../components/common'
import SEO from '../../../components/seo'
import { AuthUserContext, withAuthorization } from '../../../components/Session'
const Orders = React.lazy(() => import('../../../Views/Orders/Orders'));

const IndexPage = () => {
    const isSSR = typeof window === "undefined"
    return (
        <>
            {!isSSR && (
                <Suspense fallback={<Loading />}>
                    <SEO title="Galleries" />
                    <AuthUserContext.Consumer>
                        {authUser => (
                            authUser.isAdmin ?
                                <Orders />
                                : navigate("/dashboard")
                        )}
                    </AuthUserContext.Consumer>
                </Suspense>
            )}
        </>
    )
}

const condition = authUser => !!authUser

export default compose(
    withAuthorization(condition),
)(IndexPage)